import React from 'react';
import { createContext, Dispatch, FC, useReducer } from 'react';
import { GetTestResultResponse } from '../../api/types';
import { ResultAction, resultReducer } from '../../utils/resultReducer';

export const ResultContext = createContext<{ result: GetTestResultResponse; dispatch: Dispatch<ResultAction> }>({
	result: {
		complete: false,
	},
	dispatch: () => {},
});

export const ResultProvider: FC = ({ children }) => {
	const [result, dispatch] = useReducer(resultReducer, { complete: false });

	return <ResultContext.Provider value={{ result, dispatch }}>{children}</ResultContext.Provider>;
};
