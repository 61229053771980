import React, { FC, memo } from 'react';

export type Props = {};

export const Text: FC<Props> = memo(props => {
	const { children } = props;

	return <p {...props}>{children}</p>;
});

Text.displayName = 'Text';
