import { GetTestResultResponse } from '../api/types';

export enum ResultActionTypes {
	SET = 'set',
	CLEAR = 'clear',
}

export interface ResultAction {
	type: ResultActionTypes;
	payload?: GetTestResultResponse;
}

export const resultReducer = (state: GetTestResultResponse, action: ResultAction) => {
	if (action.type === ResultActionTypes.SET && action.payload) {
		return action.payload;
	}

	if (action.type === ResultActionTypes.CLEAR) {
		return { complete: false };
	}

	return state;
};
