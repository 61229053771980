import React, { FC, memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { ResultProvider } from '../molecules/ResultProvider';
import Arrow from '../../img/arrow.svg';
import PagespeedLogo from '../../img/pagespeed-logo.svg';
import Twitter from '../../img/twitter.svg';
import Facebook from '../../img/facebook.svg';
import LinkedIn from '../../img/linkedIn.svg';
// import Share from '../../img/share.svg';
// import ShareTwitter from '../../img/share-twitter.svg';
import 'lazysizes';
import 'react-hook-form'; // Keep this here, pushes the package to commons chunk, instead of each individual chunks
// import { useClickOutside } from 'react-click-outside-hook';

import '../../css/style.scss';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

export type Props = {};

export const Layout: FC<Props> = memo(props => {
	const { children } = props;

	const [isHeaderFixed, setHeaderFixed] = useState(false);
	const handleScroll = () => {
		setHeaderFixed(window.scrollY >= 50);
	};

	const [isMenuOpen, setMenuState] = useState(false);

	// const [ref, hasClickedOutside] = useClickOutside();

	// useEffect(() => {
	// 	hasClickedOutside && setMenuState(false);
	// }, [hasClickedOutside, isMenuOpen]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<div className="mother" id="mother">
			<Helmet>
				<html lang="cs" />
				<meta name="description" content="Víme, jak váš web zrychlit!" />

				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="PageSpeed.cz - Když na rychlosti záleží" />
				<meta name="twitter:description" content="Víme, jak váš web zrychlit!" />
				<meta name="twitter:image" content="/img/pagespeedcz-twitter___share-400x400.png" />

				<meta property="og:title" content="PageSpeed.cz - Když na rychlosti záleží" />
				<meta property="og:description" content="Víme, jak váš web zrychlit!" />
				<meta property="og:image" content="/img/pagespeedcz-fb___shared-1200x630.png" />
				<meta property="og:site_name" content="PageSpeed.cz" />
				<meta property="og:url" content="https://pagespeed.cz/" />

				<link rel="apple-touch-icon" sizes="180x180" href="/img/favicon/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/img/favicon/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/img/favicon/favicon-16x16.png" />
				<link rel="manifest" href="/img/favicon/site.webmanifest" />
				<link rel="mask-icon" href="/img/favicon/safari-pinned-tab.svg" color="#5bbad5" />
				<link rel="shortcut icon" href="/img/favicon/favicon.ico" />
				<meta name="msapplication-TileColor" content="#192164" />
				<meta name="msapplication-config" content="/img/favicon/browserconfig.xml" />
				<meta name="theme-color" content="#ffffff" />

				<script>
					{`
						setTimeout(function () {
							var className = document.documentElement.className;
							className = className.replace('no-js', 'js');

							(function() {
								var mediaHover = window.matchMedia('(hover: none), (pointer: coarse), (pointer: none)');
								mediaHover.addListener(function(media) {
									document.documentElement.classList[media.matches ? 'add' : 'remove']('no-hoverevents');
									document.documentElement.classList[!media.matches ? 'add' : 'remove']('hoverevents');
								});
								className += (mediaHover.matches ? ' no-hoverevents' : ' hoverevents');
							})();
							var ua = navigator.userAgent.toLowerCase();
							var isIOS = /ipad|iphone|ipod/.test(ua) && !window.MSStream;

							if (isIOS === true) {
								var viewportTag = document.querySelector("meta[name=viewport]");
								viewportTag.setAttribute("content", "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no");
							}

							document.documentElement.className = className;

						}, 10);
					`}
				</script>
			</Helmet>
			<header className={`header${isHeaderFixed ? ' header--fixed' : ''}${isMenuOpen ? ' menu-open' : ''}`}>
				<div className="row-main">
					<div className="header__wrap">
						<p className="header__logo">
							<Link to="/" className="header__logo-svg">
								<PagespeedLogo />
								<span className="u-vhide">PageSpeed.cz</span>
							</Link>
						</p>
						<div className="header__menu">
							<button onClick={() => setMenuState(!isMenuOpen)} className="header__btn">
								<span className="header__btn-line" />
								<span className="header__btn-line" />
								<span className="header__btn-line" />
								<span className="u-vhide">Menu</span>
							</button>
							<div className="header__menu-holder">
								<nav className="m-main">
									<ul className="m-main__list">
										<li className="m-main__item">
											<Link to="/" className="m-main__link" activeClassName={'is-active'}>
												Testování rychlosti
											</Link>
										</li>
										<li className="m-main__item">
											<Link
												to="/audit/"
												className="m-main__link"
												activeClassName={'is-active'}
												partiallyActive={true}
											>
												Audit webu
											</Link>
										</li>
									</ul>
								</nav>
								<div className="b-social">
									<p className="b-social__list">
										<OutboundLink
											href="https://twitter.com/pagespeedcz"
											className="b-social__item b-social__item--tw"
											target="_blank"
											rel="noopener noreferrer"
										>
											<Twitter />
											<span className="u-vhide-mdUp">Twitter</span>
										</OutboundLink>
										<OutboundLink
											href="https://www.facebook.com/Pagespeedcz"
											className="b-social__item b-social__item--fb"
											target="_blank"
											rel="noopener noreferrer"
										>
											<Facebook />
											<span className="u-vhide-mdUp">Facebook</span>
										</OutboundLink>
										<OutboundLink
											href="https://www.linkedin.com/company/pagespeed-cz/"
											className="b-social__item b-social__item--linked"
											target="_blank"
											rel="noopener noreferrer"
										>
											<LinkedIn />
											<span className="u-vhide-mdUp">Linked in</span>
										</OutboundLink>
										{/* <OutboundLink
											href="https://twitter.com/intent/tweet?text=Víme, jak zrychlit váš web - https://pagespeed.cz/"
											className="b-social__item b-social__item--share"
											target="_blank"
											rel="noopener noreferrer"
										>
											<ShareTwitter />
											<span className="u-vhide-mdUp">Sdílet na Twitter</span>
										</OutboundLink>
										<OutboundLink
											href="https://www.facebook.com/dialog/share?app_id=10150089895695224&display=popup&href=https://pagespeed.cz"
											className="b-social__item b-social__item--share"
											target="_blank"
											rel="noopener noreferrer"
										>
											<Share />
											<span className="u-vhide-mdUp">Sdílet na Facebook</span>
										</OutboundLink> */}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
			<main className="main">
				<ResultProvider>{children}</ResultProvider>
			</main>
			<footer className="footer">
				<div className="row-main">
					<a href="#mother" className="footer__btn">
						<Arrow />
						<span className="u-vhide">Zpět nahoru</span>
					</a>
					<div className="footer__text">
						<p>© {new Date().getUTCFullYear()} PageSpeed.cz. Všechna práva vyhrazena.</p>
						<p>Pro měření využíváme open-source technologii Lighthouse společnosti Google.</p>
						<p>
							<Link to="/faq/">Často kladené dotazy</Link> |{' '}
							<Link to="/privacy/">Ochrana osobních údajů</Link> | <Link to="/cookies/">Cookies</Link>{' '}
						</p>
						<p>
							E-mail: <OutboundLink href="mailto:info@pagespeed.cz">info@pagespeed.cz</OutboundLink> –
							Telefon: <OutboundLink href="tel:724071700">+420&nbsp;724&nbsp;071&nbsp;700</OutboundLink>
						</p>
					</div>
				</div>
			</footer>
		</div>
	);
});

Layout.displayName = 'Layout';
