import React from 'react';
import { FC, memo } from 'react';

type Props = {
	type?: 'wide';
};

export const RowMain: FC<Props> = memo(({ children }) => {
	return <div className="row-main">{children}</div>;
});

RowMain.displayName = 'RowMain';
